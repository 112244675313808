@charset "utf-8";

@use '../../foundation/variable' as v;
@use '../../foundation/mixin' as mx;

/*============================================
News一覧・詳細ページ
============================================*/
.p-news, .p-news-single {
    @include mx.mq('sp') {
        margin-top: 70px;
    }
}
.p-news .p-cmn-page__head__inner,
.p-news-single .p-cmn-page__head__inner {
    background: url(../../../img/page_news_bg.jpg) no-repeat center center / cover;

    @include mx.mq('sp') {
        background-position: right center;
    }

    .c-under-tit {
        padding-bottom: 3.3rem;
        margin-right: -17px;

        @include mx.mq('sp') {
            padding-bottom: 2.4rem;
            margin-right: -6px;
        }

        span {
            background-image: url(../../../img/page_news_bg.jpg);
            color: rgba(255, 255, 255, 0.5);
            padding-bottom: 1.7rem;
            filter: brightness(130%);

            @include mx.mq('sp') {
                padding-bottom: .8rem;
            }
        }

        span:nth-child(1) {
            background-position: bottom -17% right 40.4%;
            background-size: 420%;

            @include mx.mq('sp') {
                background-position: bottom -15% right 38%;
                background-size: 500%;
            }
        }

        span:nth-child(2) {
            padding: 0 .1em 1.7rem;
            background-position: bottom -26% right 23.7%;
            background-size: 1050%;

            @include mx.mq('sp') {
                background-position: bottom -105% right 24%;
                background-size: 1060%;
            }
        }

        span:nth-child(3) {
            background-position: bottom -1% right 3.6%;
            background-size: 570%;

            @include mx.mq('sp') {
                background-position: bottom -6% right 9%;
                background-size: 650%;
            }
        }
    }
}
.p-news-single .l-container {
    width: 85%;
    max-width: 1000px;

    @include mx.mq('sp') {
        width: 100%;
    }
}
.p-news__cont {
    position: relative;
    padding: 10rem 0 15rem;

    @include mx.mq('sp') {
        padding: 7.5rem 0;
        overflow: hidden;
    }

    .l-container {
        width: 85%;
        max-width: 990px;

        @include mx.mq('sp') {
            width: 100%;
        }
    }
}
.p-news__article__wrapper {
    margin: 13rem 0;

    @include mx.mq('sp') {
        margin: 7.5rem 0 8.5rem;
    }
}
.p-news__article:nth-child(n+2) {
    margin-top: 8rem;

    @include mx.mq('sp') {
        margin-top: 5rem;
    }
}
.p-news__article:nth-child(2n+1) {
    @include mx.mq('sp') {
        transform: translateX(calc(7.7% + 2.5rem));
    }
}
.p-news__article__inner {
    display: flex;
    align-items: center;

    @include mx.mq('sp') {
        display: block;
    }
}
.p-news__article__img {
    position: relative;
    width: calc(260 / 990 * 100%);
    padding-top: 22.2%;
    overflow: hidden;

    @include mx.mq('sp') {
        width: calc(300 / 325 * 100%);
        padding-top: 50.8%;
    }

    a {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform .6s;
    }
}
.p-news__article__txtArea {
    width: calc(calc(730 / 990 * 100%) - 5rem);
    margin-left: 5rem;

    @include mx.mq('sp') {
        width: 100%;
        margin-top: 1.5rem;
        margin-left: 0;
    }

    .c-btn {
        margin-top: 4rem;

        @include mx.mq('sp') {
            margin-top: 3.3rem;
        }
    }
}
.p-news__article__meta {

    time {
        display: inline-block;
        font-family: v.$font_en-title;
        font-size: 2.1rem;
        font-weight: 600;
        letter-spacing: .05em;
        vertical-align: top;
    
        @include mx.mq('sp') {
            font-size: 1.9rem;
        }
    }

    .c-label {
        transform: unset;
    }
}
.p-news__article__tit {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: .05em;
    line-height: 2;
    margin-top: 2rem;

    @include mx.mq('sp') {
        font-size: 1.5rem;
        letter-spacing: .025em;
        padding-right: 5rem;
        margin-top: 1.4rem;
    }
}
.p-news__cont .c-pagination:last-child {
    justify-content: center;
}
.p-news__detail {
    position: relative;
    padding: 10rem 0 13.5rem;

    @include mx.mq('sp') {
        padding: 7.5rem 0;
        overflow: hidden;
    }
}
.p-news__detail__meta {
    @include mx.mq('sp') {
        display: flex;
        justify-content: flex-end;
        margin-right: -2.5rem;
    }

    time {
        display: inline-block;
        font-family: v.$font_en-title;
        font-size: 2.1rem;
        font-weight: 600;
        letter-spacing: 0.05em;
        vertical-align: text-top;
        margin-top: -1px;

        @include mx.mq('sp') {
            font-size: 1.9rem;
        }
    }

    .c-label {
        transform: unset;
    }
}
.p-news__detail__img {
    position: relative;
    padding-top: calc(500 / 1000 * 100%);
    margin-top: 3rem;

    @include mx.mq('sp') {
        width: calc(300 / 325 * 100%);
        padding-top: 77%;
        margin-top: 1.5rem;
        transform: translateX(calc(8.5% + 2.5rem));
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.p-news__detail__body {
    margin-top: 4.8rem;

    @include mx.mq('sp') {
        margin-top: 3.5rem;
    }
}
.p-news__detail__tit {
    position: relative;
    font-size: 2.6rem;
    font-weight: 500;
    letter-spacing: .08em;
    line-height: calc(46 / 26);
    color: #9b915a;
    padding-bottom: 3.2rem;

    @include mx.mq('sp') {
        font-size: 1.8rem;
        letter-spacing: .04em;
        line-height: calc(55 / 36);
        padding-bottom: 2rem;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-image: linear-gradient(to right, #929494, #929494 4px, transparent 4px, transparent 8px);
        background-size: 8px 2px;
        background-position: left bottom;
        background-repeat: repeat-x;

        @include mx.mq('sp') {
            background-image: linear-gradient(to right, #929494, #929494 2px, transparent 2px, transparent 4px);
            background-size: 4px 1px;
        }
    }
}
.p-news__detail__txtArea {
    margin-top: 5.7rem;

    @include mx.mq('sp') {
        margin-top: 2.7rem;
    }
}
.p-news__detail__txtArea p,
.p-news__detail__txt {
    font-size: 2rem;
    letter-spacing: .08em;
    line-height: calc(46 / 20);
    color: #5b6062;

    @include mx.mq('sp') {
        font-size: 1.3rem;
        letter-spacing: .04em;
        line-height: calc(55 / 26);
    }

    &:nth-child(n+2) {
        margin-top: 4.6rem;

        @include mx.mq('sp') {
            margin-top: 3.6rem;
        }
    }
}
.p-news__detail__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 11.7rem;

    @include mx.mq('sp') {
        margin-top: 6.5rem;
    }

    .c-btn {
        width: 10rem;
        padding-bottom: 1rem;
        transform: translateY(-6px);

        @include mx.mq('sp') {
            width: 7.5rem;
            padding-bottom: .8rem;
            transform: translateY(-4px);
        }
    }

    .c-btn:first-child {
        text-align: right;

        svg {
            right: unset;
            left: -10px;
        }

        &::before {
            right: unset;
            left: 0;
        }

        &::after {
            right: unset;
            left: 0;
            transform: rotate(135deg);

        }
    }

    .to-archive {
        display: inline-block;
        width: 13rem;
        font-family: v.$font_en;
        font-style: italic;
        font-size: 2rem;
        letter-spacing: .05em;
        text-align: center;
        border: 2px solid v.$base_color;
        padding: 2rem 1.8rem 1.6rem;
        border-radius: 3rem;
        margin-left: 3rem;
        margin-right: 3rem;
        transition: background .3s, color .3s;

        @include mx.mq('sp') {
            width: 9.7rem;
            font-size: 1.5rem;
            letter-spacing: .025em;
            border: 1px solid v.$base_color;
            padding: 1.5rem 0 1.1rem;
            margin-left: 2.2rem;
            margin-right: 2.2rem;
        }
    }
}
@media (hover: hover) and (pointer: fine) {
	.p-news__article a:hover .p-news__article__img img {
        transform: scale(1.2);
    }
    .p-news__detail__btn .to-archive:hover {
        background: v.$base_color;
        color: #fff;
    }
}
@media screen and (min-width: 768px) and (max-width: 1300px) {
    .p-news__cont .c-tit--vertical,
    .p-news__detail .c-tit--vertical {
        left: 2rem;
    }
}
