@charset "UTF-8";
/*============================================
News一覧・詳細ページ
============================================*/
@media only screen and (max-width: 767px) {
  .p-news, .p-news-single {
    margin-top: 70px;
  }
}

.p-news .p-cmn-page__head__inner,
.p-news-single .p-cmn-page__head__inner {
  background: url(../../../img/page_news_bg.jpg) no-repeat center center/cover;
}
@media only screen and (max-width: 767px) {
  .p-news .p-cmn-page__head__inner,
.p-news-single .p-cmn-page__head__inner {
    background-position: right center;
  }
}
.p-news .p-cmn-page__head__inner .c-under-tit,
.p-news-single .p-cmn-page__head__inner .c-under-tit {
  padding-bottom: 3.3rem;
  margin-right: -17px;
}
@media only screen and (max-width: 767px) {
  .p-news .p-cmn-page__head__inner .c-under-tit,
.p-news-single .p-cmn-page__head__inner .c-under-tit {
    padding-bottom: 2.4rem;
    margin-right: -6px;
  }
}
.p-news .p-cmn-page__head__inner .c-under-tit span,
.p-news-single .p-cmn-page__head__inner .c-under-tit span {
  background-image: url(../../../img/page_news_bg.jpg);
  color: rgba(255, 255, 255, 0.5);
  padding-bottom: 1.7rem;
  filter: brightness(130%);
}
@media only screen and (max-width: 767px) {
  .p-news .p-cmn-page__head__inner .c-under-tit span,
.p-news-single .p-cmn-page__head__inner .c-under-tit span {
    padding-bottom: 0.8rem;
  }
}
.p-news .p-cmn-page__head__inner .c-under-tit span:nth-child(1),
.p-news-single .p-cmn-page__head__inner .c-under-tit span:nth-child(1) {
  background-position: bottom -17% right 40.4%;
  background-size: 420%;
}
@media only screen and (max-width: 767px) {
  .p-news .p-cmn-page__head__inner .c-under-tit span:nth-child(1),
.p-news-single .p-cmn-page__head__inner .c-under-tit span:nth-child(1) {
    background-position: bottom -15% right 38%;
    background-size: 500%;
  }
}
.p-news .p-cmn-page__head__inner .c-under-tit span:nth-child(2),
.p-news-single .p-cmn-page__head__inner .c-under-tit span:nth-child(2) {
  padding: 0 0.1em 1.7rem;
  background-position: bottom -26% right 23.7%;
  background-size: 1050%;
}
@media only screen and (max-width: 767px) {
  .p-news .p-cmn-page__head__inner .c-under-tit span:nth-child(2),
.p-news-single .p-cmn-page__head__inner .c-under-tit span:nth-child(2) {
    background-position: bottom -105% right 24%;
    background-size: 1060%;
  }
}
.p-news .p-cmn-page__head__inner .c-under-tit span:nth-child(3),
.p-news-single .p-cmn-page__head__inner .c-under-tit span:nth-child(3) {
  background-position: bottom -1% right 3.6%;
  background-size: 570%;
}
@media only screen and (max-width: 767px) {
  .p-news .p-cmn-page__head__inner .c-under-tit span:nth-child(3),
.p-news-single .p-cmn-page__head__inner .c-under-tit span:nth-child(3) {
    background-position: bottom -6% right 9%;
    background-size: 650%;
  }
}

.p-news-single .l-container {
  width: 85%;
  max-width: 1000px;
}
@media only screen and (max-width: 767px) {
  .p-news-single .l-container {
    width: 100%;
  }
}

.p-news__cont {
  position: relative;
  padding: 10rem 0 15rem;
}
@media only screen and (max-width: 767px) {
  .p-news__cont {
    padding: 7.5rem 0;
    overflow: hidden;
  }
}
.p-news__cont .l-container {
  width: 85%;
  max-width: 990px;
}
@media only screen and (max-width: 767px) {
  .p-news__cont .l-container {
    width: 100%;
  }
}

.p-news__article__wrapper {
  margin: 13rem 0;
}
@media only screen and (max-width: 767px) {
  .p-news__article__wrapper {
    margin: 7.5rem 0 8.5rem;
  }
}

.p-news__article:nth-child(n+2) {
  margin-top: 8rem;
}
@media only screen and (max-width: 767px) {
  .p-news__article:nth-child(n+2) {
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 767px) {
  .p-news__article:nth-child(2n+1) {
    transform: translateX(calc(7.7% + 2.5rem));
  }
}

.p-news__article__inner {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .p-news__article__inner {
    display: block;
  }
}

.p-news__article__img {
  position: relative;
  width: 26.2626262626%;
  padding-top: 22.2%;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .p-news__article__img {
    width: 92.3076923077%;
    padding-top: 50.8%;
  }
}
.p-news__article__img a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.p-news__article__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s;
}

.p-news__article__txtArea {
  width: calc(73.7373737374% - 5rem);
  margin-left: 5rem;
}
@media only screen and (max-width: 767px) {
  .p-news__article__txtArea {
    width: 100%;
    margin-top: 1.5rem;
    margin-left: 0;
  }
}
.p-news__article__txtArea .c-btn {
  margin-top: 4rem;
}
@media only screen and (max-width: 767px) {
  .p-news__article__txtArea .c-btn {
    margin-top: 3.3rem;
  }
}

.p-news__article__meta time {
  display: inline-block;
  font-family: "Cormorant", serif;
  font-size: 2.1rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  vertical-align: top;
}
@media only screen and (max-width: 767px) {
  .p-news__article__meta time {
    font-size: 1.9rem;
  }
}
.p-news__article__meta .c-label {
  transform: unset;
}

.p-news__article__tit {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 2;
  margin-top: 2rem;
}
@media only screen and (max-width: 767px) {
  .p-news__article__tit {
    font-size: 1.5rem;
    letter-spacing: 0.025em;
    padding-right: 5rem;
    margin-top: 1.4rem;
  }
}

.p-news__cont .c-pagination:last-child {
  justify-content: center;
}

.p-news__detail {
  position: relative;
  padding: 10rem 0 13.5rem;
}
@media only screen and (max-width: 767px) {
  .p-news__detail {
    padding: 7.5rem 0;
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .p-news__detail__meta {
    display: flex;
    justify-content: flex-end;
    margin-right: -2.5rem;
  }
}
.p-news__detail__meta time {
  display: inline-block;
  font-family: "Cormorant", serif;
  font-size: 2.1rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  vertical-align: text-top;
  margin-top: -1px;
}
@media only screen and (max-width: 767px) {
  .p-news__detail__meta time {
    font-size: 1.9rem;
  }
}
.p-news__detail__meta .c-label {
  transform: unset;
}

.p-news__detail__img {
  position: relative;
  padding-top: 50%;
  margin-top: 3rem;
}
@media only screen and (max-width: 767px) {
  .p-news__detail__img {
    width: 92.3076923077%;
    padding-top: 77%;
    margin-top: 1.5rem;
    transform: translateX(calc(8.5% + 2.5rem));
  }
}
.p-news__detail__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-news__detail__body {
  margin-top: 4.8rem;
}
@media only screen and (max-width: 767px) {
  .p-news__detail__body {
    margin-top: 3.5rem;
  }
}

.p-news__detail__tit {
  position: relative;
  font-size: 2.6rem;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 1.7692307692;
  color: #9b915a;
  padding-bottom: 3.2rem;
}
@media only screen and (max-width: 767px) {
  .p-news__detail__tit {
    font-size: 1.8rem;
    letter-spacing: 0.04em;
    line-height: 1.5277777778;
    padding-bottom: 2rem;
  }
}
.p-news__detail__tit::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(to right, #929494, #929494 4px, transparent 4px, transparent 8px);
  background-size: 8px 2px;
  background-position: left bottom;
  background-repeat: repeat-x;
}
@media only screen and (max-width: 767px) {
  .p-news__detail__tit::after {
    background-image: linear-gradient(to right, #929494, #929494 2px, transparent 2px, transparent 4px);
    background-size: 4px 1px;
  }
}

.p-news__detail__txtArea {
  margin-top: 5.7rem;
}
@media only screen and (max-width: 767px) {
  .p-news__detail__txtArea {
    margin-top: 2.7rem;
  }
}

.p-news__detail__txtArea p,
.p-news__detail__txt {
  font-size: 2rem;
  letter-spacing: 0.08em;
  line-height: 2.3;
  color: #5b6062;
}
@media only screen and (max-width: 767px) {
  .p-news__detail__txtArea p,
.p-news__detail__txt {
    font-size: 1.3rem;
    letter-spacing: 0.04em;
    line-height: 2.1153846154;
  }
}
.p-news__detail__txtArea p:nth-child(n+2),
.p-news__detail__txt:nth-child(n+2) {
  margin-top: 4.6rem;
}
@media only screen and (max-width: 767px) {
  .p-news__detail__txtArea p:nth-child(n+2),
.p-news__detail__txt:nth-child(n+2) {
    margin-top: 3.6rem;
  }
}

.p-news__detail__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 11.7rem;
}
@media only screen and (max-width: 767px) {
  .p-news__detail__btn {
    margin-top: 6.5rem;
  }
}
.p-news__detail__btn .c-btn {
  width: 10rem;
  padding-bottom: 1rem;
  transform: translateY(-6px);
}
@media only screen and (max-width: 767px) {
  .p-news__detail__btn .c-btn {
    width: 7.5rem;
    padding-bottom: 0.8rem;
    transform: translateY(-4px);
  }
}
.p-news__detail__btn .c-btn:first-child {
  text-align: right;
}
.p-news__detail__btn .c-btn:first-child svg {
  right: unset;
  left: -10px;
}
.p-news__detail__btn .c-btn:first-child::before {
  right: unset;
  left: 0;
}
.p-news__detail__btn .c-btn:first-child::after {
  right: unset;
  left: 0;
  transform: rotate(135deg);
}
.p-news__detail__btn .to-archive {
  display: inline-block;
  width: 13rem;
  font-family: "Josefin Sans", sans-serif;
  font-style: italic;
  font-size: 2rem;
  letter-spacing: 0.05em;
  text-align: center;
  border: 2px solid #333637;
  padding: 2rem 1.8rem 1.6rem;
  border-radius: 3rem;
  margin-left: 3rem;
  margin-right: 3rem;
  transition: background 0.3s, color 0.3s;
}
@media only screen and (max-width: 767px) {
  .p-news__detail__btn .to-archive {
    width: 9.7rem;
    font-size: 1.5rem;
    letter-spacing: 0.025em;
    border: 1px solid #333637;
    padding: 1.5rem 0 1.1rem;
    margin-left: 2.2rem;
    margin-right: 2.2rem;
  }
}

@media (hover: hover) and (pointer: fine) {
  .p-news__article a:hover .p-news__article__img img {
    transform: scale(1.2);
  }

  .p-news__detail__btn .to-archive:hover {
    background: #333637;
    color: #fff;
  }
}
@media screen and (min-width: 768px) and (max-width: 1300px) {
  .p-news__cont .c-tit--vertical,
.p-news__detail .c-tit--vertical {
    left: 2rem;
  }
}